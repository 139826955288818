<template>
  <div>
    <b-card>
      <b-card-body
        v-for="(item, i) in items"
        :key="i"
      >
        <b-media>
          <template #aside>
            <b-avatar src="/infowing.png" size="4rem" class="mr-3" />
          </template>
          <template #default>
            <div class="d-flex flex-row align-items-start justify-content-between">
              <div>
                <h5 class="my-0 mb-2">
                  <span id="notePrivateCreateAt" class="text-muted small">{{ $dateFormat(item.created_at) }}</span>
                  - <span v-if="item.author.role !== ''" class="small font-weight-bold">[{{ item.author.role }}] </span>
                  <span class="small">{{ item.author.email }}</span>
                </h5>
                <p class="mb-0">
                  {{ item.text }}
                </p>
              </div>
              <b-btn
                variant="warning"
                size="sm"
                @click="onDelete(item.id)"
              >
                <font-awesome-icon
                  class="text-white"
                  :icon="['fas', 'trash']"
                />
              </b-btn>
            </div>
          </template>
        </b-media>
      </b-card-body>
      <b-card-footer>
        <b-textarea ref="noteInput" v-model="newNote" />
        <b-btn
          variant="primary"
          size="sm"
          class="mt-2"
          @click="onStore"
        >
          {{ $t('fCustomer_note_saveBtn') }}
        </b-btn>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapComputedStateFields } from '~/mixins/globalForm'

export default {
  name: 'Notes',
  props: {
    type: {
      type: String,
      required: true,
      default: 'public' // public or private
    },
    itemId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      newNote: ''
    }
  },
  computed: {
    ...mapComputedStateFields('customerEdit', [
      'notes'
    ]),
    items () {
      return (this.notes && this.notes[this.type]) || []
    }
  },
  mounted () {
    // open notes
    if (this.$route.hash === '#notes') {
      this.$nextTick(() => {
        if (this.$refs.noteInput) {
          this.$refs.noteInput.$el.focus()
        }
      })
    }
  },
  methods: {
    ...mapActions({
      addNote: 'forms/customerEdit/addNote',
      deleteNote: 'forms/customerEdit/deleteNote'
    }),
    onStore () {
      if (this.newNote !== '') {
        this.addNote({ itemId: this.itemId, note: this.newNote, type: this.type })
        this.$nextTick(() => {
          this.newNote = ''
        })
      }
    },
    onDelete (noteId) {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id: noteId }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then((value) => {
          if (value) {
            this.deleteNote({ itemId: this.itemId, type: this.type, noteId })
            this.$nextTick(() => {
              this.$forceUpdate()
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
