<template>
  <div>
    <b-card
      border-variant="danger"
      header-text-variant="secondary"
      no-body
    >
      <b-card-header header-class="bg-danger">
        <span v-shtml="$t('fCustomer_duplClone_title', { tot })" />
      </b-card-header>
      <b-card-body
        class="p-1"
      >
        <b-row
          class="row w-100 m-0"
          style="overflow: hidden;"
        >
          <b-col
            v-for="(item, i) in items"
            ref="gi"
            :key="i"
            :class="{ 'bg-danger': item.deleted }"
            class="col-12 col-md-6 col-lg-4 p-1"
            style="overflow: hidden; border: 1px solid #eae9e9; "
          >
            <user-profile-item
              v-if="item.user_profile_id"
              :user="item"
              style=""
              class="text-nowrap w-100 p-1"
            />
            <span v-else>
              {{ $t('fCustomer_dupl_noProfile') + item.user_id }}
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'PossibleClones',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      itemWidth: 0
    }
  },
  computed: {
    tot () {
      return this.items?.length || 0
    },
    itemStyle () {
      return this.itemWidth > 0 ? `width: ${this.itemWidth}px;` : 'width: 340px;'
    }
  },
  mounted () {
    this.$nextTick(() => {
      let maxW = 0
      this.$refs.gi.forEach((v) => {
        if (v) {
          if (v.offsetWidth > maxW) {
            maxW = v.offsetWidth
          }
        }
      })
      this.itemWidth = maxW
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
