<template>
  <div>
    <b-sidebar
      ref="sb"
      v-model="state"
      v-resize="onResize"
      :width="`${width}px`"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="state" class="bg-white px-1 px-lg-3">
        <data-layout
          v-if="itemId"
          ref="dtSms"
          :fields="fields"
          :filter-fields="filterFields"
          :filter="filter"
          :order="order"
          :repo="repo"
          :parent-id="itemId"
          :height="height"
          :limit="50"
          card-collapse
          @on-filter-submit="onFilterSubmit"
          @on-sort="onSort"
        >
          <template #custom-top>
            <b-progress
              v-if="smsBatch && smsBatch.id"
              :max="100"
              style="min-width: 300px;"
            >
              <b-progress-bar :value="smsBatch.progress" :label="`${smsBatch.progress}%`" />
            </b-progress>
          </template>
          <template #card(header)="{ data: { item }}">
            <div
              v-shtml="item.campaign_name"
              style="overflow:hidden; text-overflow: ellipsis;"
            />
          </template>
          <template #cell(message)="{ data: { item } }">
            <div
              class="d-flex flex-row px-2"
              :class="{'justify-content-end': item.sender_is_staff}"
            >
              <div
                class="mb-1 p-2 sms-msg"
                :class="getMsgCls(item)"
              >
                <div class="d-flex flex-row justify-content-between align-items-center small text-secondary mb-1">
                  <span class="small">{{ item.id }}</span>
                  <span />
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center small text-secondary mb-1">
                  <span v-if="!!item.sender_is_staff">
                    <span v-if="item.sender.role !== ''" class="font-weight-bold">[{{ item.sender.role }}] </span>
                    <span class="">{{ item.sender.email }}</span>
                  </span>
                  <div v-else>
                    <user-profile-link :user="item.sender" />
                  </div>
                </div>
                <span
                  v-shtml="item.message"
                  class="d-block mb-2"
                />
                <div class="d-flex flex-row justify-content-between align-items-center small text-secondary mb-1">
                  <span />
                  <span>{{ $dateFormat(item.date) }}</span>
                </div>
              </div>
            </div>
          </template>
          <template #cell(status)="{ data: { item } }">
            <div class="small">
              {{ item.status }}
            </div>
          </template>
          <template #cell(info)="{ data: { item } }">
            <table class="small w-100">
              <tr v-if="item.campaign_name">
                <th>{{ $t('fCustomer_usrSms_f_info_campaign') }}: </th>
                <td>{{ item.campaign_name }}</td>
              </tr>
              <tr v-if="item.message_type">
                <th>{{ $t('fCustomer_usrSms_f_info_type') }}: </th>
                <td>{{ item.message_type }}</td>
              </tr>
              <tr v-if="item.total_sent">
                <th>{{ $t('fCustomer_usrSms_f_info_total') }}: </th>
                <td>{{ item.total_sent }}</td>
              </tr>
              <tr>
                <th>{{ $t('fCustomer_usrSms_f_info_from') }}: </th>
                <td>{{ item.sender_num }}</td>
              </tr>
              <tr>
                <th>{{ $t('fCustomer_usrSms_f_info_to') }}: </th>
                <td>{{ item.receiver_num }}</td>
              </tr>
            </table>
          </template>
          <template #custom-actions>
            <b-dropdown-item-button
              variant="success"
              @click="onShowSmsNew"
            >
              <font-awesome-icon
                color="success"
                :icon="['fas', 'comments']"
              />
              <span>{{ $t('eDoc_form_a_create') }}</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              variant="secondary"
              :disabled="smsBatch && !!smsBatch.id"
              @click="onRefresh"
            >
              <font-awesome-icon
                :spin="smsBatch && !!smsBatch.id"
                :icon="['fas', 'arrows-rotate']"
              />
              <span>{{ $t('eDoc_form_a_refresh') }}</span>
            </b-dropdown-item-button>
          </template>
        </data-layout>
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          {{ parentTitle }}
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div v-if="!isMobile" class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>

    <user-sms-new
      v-model="userSmsNewState"
      :user-id="itemId"
      :mobile="mobile"
      @sent="onSmsSent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserSmsNew from './UserSmsNew'
import { mapComputedFormFields, mapComputedStateFields } from '@/mixins/globalForm'

export default {
  name: 'UserSmsHistory',
  components: { UserSmsNew },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    info: {
      type: Object,
      default: () => ({})
    },
    parentTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      repo: 'customers.sms',
      userSmsNewState: false,
      filterState: false,
      filter: [],
      order: { by: 'date', dir: 'desc' },
      state: this.value,
      busy: false,
      height: 500,
      width: 300
    }
  },
  async fetch () {
    if (this.$refs.dtSms) {
      await this.$refs.dtSms.$fetch()
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device',
      getByName: 'repos/getByName'
    }),
    ...mapComputedFormFields('customerEdit', [
      'mobile'
    ]),
    ...mapComputedStateFields('customerEdit', [
      'smsBatch'
    ]),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    displayTitle () {
      return `${this.$t('fCustomer_usrSms_title')}`
    },
    fields () {
      const fields = [
        {
          key: 'message',
          label: this.$t('fCustomer_usrSms_f_text'),
          thClass: 'text-center',
          tdClass: 'text-wrap',
          sortable: true,
          cardNoLabel: true
        },
        {
          key: 'status',
          label: this.$t('fCustomer_usrSms_f_status'),
          sortable: true,
          class: 'col-2'
        },
        {
          key: 'info',
          label: this.$t('fCustomer_usrSms_f_info'),
          sortable: false,
          class: 'col-2'
        }
      ]
      return fields
    },
    filterFields () {
      return [
        {
          label: this.$t('fCustomer_usrSms_f_delivery_date'),
          key: 'date',
          type: 'date-range',
          size: '4'
        },
        {
          label: this.$t('fCustomer_usrSms_f_status'),
          key: 'status',
          type: 'select',
          size: '2'
        }
      ]
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (!n) {
        this.flushData()
      }
      this.$nextTick(() => {
        this.calcHeight()
        this.calcWidth()
      })
      this.call({
        repo: this.repo,
        method: 'filterData',
        parentId: this.itemId
      })
    },
    'smsBatch.id': {
      handler (n) {
        if (!n) {
          this.$refs.dtSms?.$fetch()
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcHeight()
      this.calcWidth()
    })
    if (window) {
      window.addEventListener('resize', this.calcHeight)
    }
  },
  beforeDestroy () {
    if (window) {
      window.removeEventListener('resize', this.calcHeight)
    }
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError',
      refreshSms: 'forms/customerEdit/refreshSms'
    }),
    onSort (order) {
      this.order = order
    },
    onFilterSubmit (filter) {
      this.filter = filter
      this.$nextTick(() => {
        this.$refs.dtSms.$fetch()
      })
    },
    flushData () {
      this.flush({
        repo: this.repo,
        method: 'index',
        id: this.itemId,
        idx: this.idx
      })
    },
    onCancel () {
      this.$emit('input', false)
    },
    async onRefresh () {
      this.busy = true
      await this.refreshSms({
        itemId: this.itemId
      })
      this.busy = false
    },
    onShowSmsNew () {
      this.userSmsNewState = true
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    onSmsSent () {
      this.$nextTick(() => {
        this.$fetch()
      })
    },
    calcHeight () {
      if (!process.server && window) {
        const $sidebar = this.$refs.sb?.$el?.querySelector('.b-sidebar-body')
        const $pager = this.$refs.sb?.$el?.querySelector('.pager')
        const $tabsNav = this.$refs.sb?.$el?.querySelector('.b-sidebar-body .tabs .nav')?.parentElement
        const sidebarHeight = $sidebar?.clientHeight || 0
        const pagerHeight = $pager?.clientHeight || 0
        const tabsNavHeight = $tabsNav?.clientHeight || 0
        // calc.
        this.height = sidebarHeight - pagerHeight - tabsNavHeight
      }
    },
    calcWidth () {
      let width = 0
      if (!process.server) {
        if (this.deviceInfo?.type === 'mobile') {
          width = document.body.offsetWidth
        } else {
          width = Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        }
      }
      this.width = width
    },
    onResize () {
      this.calcHeight()
      this.calcWidth()
    },
    getMsgCls (item) {
      const cls = []
      if (!item.sender_is_staff) {
        cls.push('received')
        cls.push('bg-info')
      } else if (item.status !== 'DLVRD' || item.message === '') {
        cls.push('bg-warning')
      } else {
        cls.push('bg-success')
      }
      return cls.join(' ')
    }
  }
}
</script>

<style>
.sms-msg {
  border: 1px solid rgba(38, 159, 65, 0.19) !important;
  border-radius: 10px 10px 0 10px;
  max-width: 360px;
}
.sms-msg.received {
  border: 1px solid rgba(38, 159, 65, 0.19) !important;
  border-radius: 10px 10px 10px 0;
  max-width: 360px;
}
</style>
