<template>
  <b-modal
    v-model="state"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    @click.prevent.stop
  >
    <div class="h1 d-inline-flex align-items-center text-info w-100">
      <font-awesome-icon :icon="['fas', 'comments']" class="mr-3" />
      <div class="h3 font-weight-bold">
        {{ $t('fCustomer_usrSmsNew_title') }}
      </div>
    </div>
    <div class="body">
      <b-form-group
        v-if="!done"
        id="input-mobile"
        :label="$t('fCustomer_usrSmsNew_f_mobile')"
        label-for="input-mobile"
        :description="$t('fCustomer_usrSmsNew_f_mobile_desc')"
      >
        <b-form-input
          id="input-mobile"
          v-model="form.mobile"
          type="tel"
          :placeholder="$t('fCustomer_usrSmsNew_f_mobile_placeholder')"
          required
          :disabled="!!busy"
          :invalid-feedback="invalidMobileFeedback"
          :state="mobileState"
          trim
        />
      </b-form-group>
      <b-form-group
        v-if="!done"
        id="input-mobile"
        :label="$t('fCustomer_usrSmsNew_f_message')"
        label-for="input-message"
        :description="$t('fCustomer_usrSmsNew_f_message_desc')"
      >
        <b-form-textarea
          id="input-message"
          v-model="form.message"
          :placeholder="$t('fCustomer_usrSmsNew_f_message_placeholder')"
          rows="3"
          max-rows="6"
          required
          :disabled="!!busy"
          :invalid-feedback="invalidMessageFeedback"
          :state="messageState"
          trim
        />
      </b-form-group>
      <b-form-group
        v-if="!done"
        class="text-right"
      >
        <b-button
          variant="outline-secondary"
          size="sm"
          :disabled="!!busy"
          @click="onReset"
        >
          {{ $t('fCustomer_usrSmsNew_a_cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          :disabled="!!busy || !mobileState || !messageState"
          @click="onSubmit"
        >
          {{ $t('fCustomer_usrSmsNew_a_send') }}
        </b-button>
      </b-form-group>
      <div v-if="done" class="d-flex flex-column align-items-center justify-content-between">
        <font-awesome-icon
          class="text-success my-5"
          :icon="['fas', 'circle-check']"
          size="5x"
        />
        <b-button
          variant="outline-success"
          size="sm"
          :disabled="!!busy"
          @click="onReset"
        >
          {{ $t('fCustomer_usrSmsNew_a_ok') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserSmsNew',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true
    },
    mobile: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      itemId: this.userId,
      state: this.value,
      busy: false,
      done: false,
      form: {
        mobile: this.mobile || '',
        message: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    invalidMobileFeedback () {
      if (this.form.mobile.length > 0) {
        return this.$t('fCustomer_usrSmsNew_v_xch', { ch: 12 })
      }
      return this.$t('fCustomer_usrSmsNew_v_some')
    },
    mobileState () {
      return this.form.mobile.length >= 12
    },
    invalidMessageFeedback () {
      if (this.form.message.length > 0) {
        return this.$t('fCustomer_usrSmsNew_v_xch', { ch: 2 })
      }
      return this.$t('fCustomer_usrSmsNew_v_some')
    },
    messageState () {
      return this.form.message.length >= 2
    }
  },
  watch: {
    value (n) {
      this.state = n
      if (!n) {
        this.form.message = ''
        this.busy = false
        this.done = false
      }
    },
    mobile (n) {
      this.form.mobile = n
    }
  },
  methods: {
    ...mapActions({
      sendSms: 'forms/customerEdit/sendSms'
    }),
    async onSubmit () {
      this.busy = true
      await this.sendSms({
        itemId: this.itemId,
        mobile: this.form.mobile,
        message: this.form.message
      })
      this.busy = false
      this.done = true
      this.$emit('sent', true)
    },
    onReset () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
